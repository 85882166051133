.rte h2 {
  @apply mb-2 text-30-35-sg lg:text-70-80-sg font-extralight text-sg-black;
}

.rte h3 {
  @apply mb-2 text-20-25-sg lg:text-60-70-sg font-extralight text-sg-black;
}

.rte h4 {
  @apply mb-2 text-15-20-sg lg:text-50-60-sg font-extralight text-sg-black;
}

.rte h5 {
  @apply mb-2 text-15-20-sg lg:text-50-60-sg font-extralight text-sg-black;
}

.rte a {
  @apply font-medium underline underline-offset-2;
}

.rte ul {
  @apply list-none;
}

.rte ul li::before {
  @apply inline-block w-4 font-bold text-grey-medium;
  content: '\2022';
}
.rte ol {
  @apply list-decimal pl-4;
}
.rte ol li {
  @apply pl-2;
}

.rte ul li p,
.rte ol li p {
  @apply inline;
}
.rte ol li,
.rte ul li {
  @apply mb-4;
}
.rte p {
  @apply mb-3 min-h-[1px];
}
